/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import Logo from "./atrust-logo.png"
import { Nav, Navbar, Image, NavDropdown } from "react-bootstrap"
import { useAuth } from "../Auth"

const styles: { [key: string]: React.CSSProperties } = {
  header: {
    backgroundColor: "#5b6163"
  }
}

const Header: React.FC = () => {
  const { auth0Client, user } = useAuth()
  const [userName, setUserName] = useState("")
  useEffect(() => {
    if (user) {
      setUserName(user.name)
    }
  }, [user])
  const logout = () => {
    if (auth0Client !== null) {
      auth0Client.logout({
        returnTo: process.env.REACT_APP_AUTH0_RETURN_URL
      })
    }
  }

  return (
    <header className="mb-4" style={styles.header}>
      <Navbar collapseOnSelect expand="lg" variant="dark" className="">
        <Navbar.Brand>
          <Link to="/">
            <Image src={Logo} />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="">
          <Nav className="pt-2">
            <Link className="text-white nav-link" to="/">
              ベンダー一覧
            </Link>
            <Link className="text-white nav-link" to="/find_cert">
              証明書検索
            </Link>
            <Link className="text-white nav-link" to="/history">
              取引履歴
            </Link>
          </Nav>
          <NavDropdown
            title={userName}
            id="nav-drojpdown"
            className="ml-auto"
            css={css`
              .dropdown-toggle::before {
              }
              .dropdown-toggle::before {
              }
              .dropdown-menu {
                @media screen and (min-width: 0px) {
                  right: auto;
                  left: 0;
                }
                @media screen and (min-width: 1000px) {
                  right: 0;
                  left: auto;
                }
              }
            `}>
            <NavDropdown.Item as={Link} to="/profile">
              プロフィール
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => logout()}>
              ログアウト
            </NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
      </Navbar>
    </header>
  )
}

export default Header
