import React, { createContext, useContext, useState } from "react"

type ToastValue = {
  isShow: boolean
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>
  Toast: () => JSX.Element
  createToast: React.Dispatch<React.SetStateAction<() => JSX.Element>>
}

const ToastContext = createContext({} as ToastValue)
export const useToast = () => useContext<ToastValue>(ToastContext)

export const ToastProvider: React.FC = ({ children }) => {
  const C = () => <div></div>
  const [isShow, setIsShow] = useState(true)
  const [Component, setComponent] = useState<() => JSX.Element>(() => C)

  const value = {
    isShow,
    setIsShow,
    Toast: Component,
    createToast: setComponent
  }

  return (
    <ToastContext.Provider value={value}>
      {isShow ? <Component /> : <></>}
      {children}
    </ToastContext.Provider>
  )
}
