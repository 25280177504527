import axios, { CancelTokenSource } from "axios"
import { Image, Images, QueryParams, HistoryFilterParams, AxiosResponseType } from "./types"
import moment from "moment"

export const axiosWithToken = (token: string, responseType?: AxiosResponseType) =>
  axios.create({
    url: "/",
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    },
    responseType: responseType || "json"
  })

export const toJST = (iso: string) => {
  const date = new Date(iso)
  date.setTime(date.getTime())
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const monthStr = month < 10 ? "0" + month : month
  const day = date.getDate()
  const dayStr = day < 10 ? "0" + day : day
  const h = date.getHours()
  const hStr = h < 10 ? "0" + h : h
  const i = date.getMinutes()
  const iStr = i < 10 ? "0" + i : i
  const jst = year + "/" + monthStr + "/" + dayStr + " " + hStr + ":" + iStr
  return jst
}

export const fetchImages = async (
  vendorId: string,
  appCode: string,
  assetCode: string,
  token: string,
  signal: CancelTokenSource
): Promise<Images> => {
  const a = [1, 2, 3]
  const images: Promise<Images> = Promise.all(
    a.map(
      async (i: number): Promise<Image> => {
        try {
          const res = await axiosWithToken(token).get(
            `/admin/assets/images/${vendorId}/${appCode}/${assetCode}/${i}`,
            {
              cancelToken: signal.token,
              responseType: "arraybuffer",
              headers: {
                "Content-Type": "image/*"
              }
            }
          )

          const buf = Buffer.from(res.data)
          return arraybufToImage(buf)
        } catch (e) {
          console.log("画像情報を読み込めませんでした")
          return ""
        }
      }
    )
  )
  return images
}

const arraybufToImage = (buf: Buffer) => {
  let binary = ""
  for (let i in buf) {
    binary += String.fromCharCode(buf[i])
  }
  return "data:image/jpeg;base64," + window.btoa(binary)
}

export const currencyToMessage = (currency: string) => {
  switch (currency) {
    case "JPY":
      return "円"
    case "CNY":
      return "元"
    case "USD":
      return "ドル"
  }
}

export const convertQueryParams = (params: QueryParams) => {
  const { pageNum, page, item, order } = params

  const pageParam = `pageNum=${pageNum || 10}&p=${page || 0}`
  const itemParam = item ? `&item=${item}` : ""
  const orderParam = order ? `&order=${order}` : ""

  const queryParam = pageParam + itemParam + orderParam
  return queryParam
}

export const convertFilterParams = (params: HistoryFilterParams) => {
  const { from, to, type, vendorId, appCode, assetCode, group } = params

  const vendorIdParam = `vendorId=${vendorId || "*"}`
  const appCodeParam = appCode ? `&appCode=${appCode}` : ""
  const fromParam = from ? `&from=${fmtDate(from)}` : ""
  const toParam = to ? `&to=${fmtDate(to)}` : ""
  const typeParam = type ? `&type=${type}` : ""
  const assetCodeParam = assetCode ? `&assetCode=${assetCode}` : ""
  const groupParam = group ? `&group=${group}` : ""
  
  const historyParams = vendorIdParam + appCodeParam + fromParam + toParam + typeParam + assetCodeParam + groupParam

  return historyParams
}

export const fmtDate = (date: Date) => moment(date).format("YYYY-MM-DD")

export const getMonthRange = (d: Date) => {
  const date = moment(d)
  const days = date.date()
  const from = date.subtract(days - 1 , "days").toDate()
  const to = moment(d).add(1, "days").toDate()
  return {from, to}
}

export const getLastWeekRange = (d: Date) => {
  const date = moment(d)
  const from = date.subtract(6, "days").toDate()
  const to = moment(d).add(1, "days").toDate()
  return {from, to}
}

export const getLastSixMonthRange = (d: Date) => {
  const date = moment(d)
  const f = date.subtract(5, "months")
  const from = f.subtract(f.date() - 1 , "days").toDate()
  const to = moment(d).add(1, "days").toDate()
  return {from, to}
}

export const getMonthRangeStr = (d: Date) => {
  const date = moment(d)
  const days = date.date()
  const from = date.subtract(days - 1 , "days").toDate()
  const to = moment(d).toDate()
  return {from: fmtDate(from), to: fmtDate(to) }
}

export const marshalQueryParams = (qs: string) => {
  const params: {[key: string]: string | Date | null} = {from: null, to: null}
  if(!qs) return params as HistoryFilterParams

  const queryString = qs.split("?")[1]
  const queryParams = queryString.split("&")

  queryParams.forEach(p => {
    const keyValue = p.split("=")
    params[keyValue[0]] = ["from", "to"].includes(keyValue[0]) ? new Date(keyValue[1]) : keyValue[1]
  })

  return params as HistoryFilterParams
}
