import axios from "axios"
import React, { useEffect, useState } from "react"
import { RouteComponentProps } from "react-router"
import {
  Button,
  Row,
  Col,
  Spinner,
  Card,
  ListGroup,
  Form,
  Modal
} from "react-bootstrap"
import ImageList from "../components/ImageList"
import { fetchImages, toJST } from "../util"
import { useAuth } from "../Auth"
import { Cert, Images, MaybeLoading, Vendor, App } from "../types"
import { SuccessModal, FailModal } from "../components/Modal"
import { useToast } from "../Toast"
import { Link } from "react-router-dom"
import { fetchVendor } from "../usecases/vendor"
import { fetchApp } from "../usecases/app"
import { fetchCert, deleteCert } from "../usecases/certification"

const RevokeCertificationModal: React.FC<{
  cert: Cert
  token: string
  show: boolean
  onHide: () => void
  history: any
}> = ({ cert, token, show, onHide, history }) => {
  const { createToast, isShow, setIsShow } = useToast()
  const [reason, setReason] = useState("")
  const [validated, setValidated] = useState(false)

  const submit = async (event: any) => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()

    if (form.checkValidity() === false) {
      setValidated(true)
      return
    }

    const title = "証明書の削除"
    try {
      await deleteCert(token, cert.certId, reason)
      history.push("/find_cert")
      setIsShow(true)
      createToast(() => () => (
        <SuccessModal
          show={isShow}
          handleClose={() => setIsShow(false)}
          title={title}
        />
      ))
    } catch (err) {
      const errorMessage = err.response.data.message

      setIsShow(true)
      createToast(() => () => (
        <FailModal
          show={isShow}
          handleClose={() => setIsShow(false)}
          title={title}
          message={errorMessage}
        />
      ))
    }
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Card>
        <Card.Header>証明書の削除をします</Card.Header>
        <Card.Body>
          <Card.Title>証明書ID: {cert.certId}</Card.Title>
          <Card.Body style={{ width: "100%" }}>
            <Form noValidate validated={validated} onSubmit={submit}>
              <Form.Group>
                <Row>
                  <Col sm={9}>
                    理由を入力してください
                    <span className="text-danger float-right mr-5">(必須)</span>
                  </Col>
                </Row>
                <Row>
                  <Form.Control
                    required
                    as="textarea"
                    rows="3"
                    value={reason}
                    onChange={(e: any) => setReason(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    値を入力してください
                  </Form.Control.Feedback>
                </Row>
              </Form.Group>
              <Button type="submit" variant="danger">
                削除する
              </Button>
            </Form>
          </Card.Body>
        </Card.Body>
      </Card>
    </Modal>
  )
}

const OwnerLog = (
  owners: Array<{
    originalUserId: string
    userId: string
    txId: string
    registeredDate: string
  }>
) => (
  <ListGroup variant="flush" className="profile">
    {owners.map((owner, i) => (
      <ListGroup.Item key={i}>
        <span className="item-label">登録日:</span>{" "}
        {toJST(owner.registeredDate)},<br />
        <span className="item-label">トランザクションID:</span> {owner.txId},
        <br />
        <span className="item-label">ユーザーID:</span> {owner.originalUserId}
      </ListGroup.Item>
    ))}
  </ListGroup>
)

const DetailCertPage: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
  history
}) => {
  const { token } = useAuth()
  const [certState, setCertState] = useState<MaybeLoading<Cert>>({
    data: null,
    isLoading: true
  })
  const [vendorState, setVendorState] = useState<MaybeLoading<Vendor>>({
    data: null,
    isLoading: true
  })
  const [appState, setAppState] = useState<MaybeLoading<App>>({
    data: null,
    isLoading: true
  })
  const [images, setImages] = useState<MaybeLoading<Images>>({
    data: null,
    isLoading: true
  })
  const [show, setShow] = useState(false)
  const { id } = match.params

  useEffect(() => {
    const signalForFetchImage = axios.CancelToken.source()

    const f = async () => {
      if (!token) {
        return
      }
      const cert = await fetchCert(token, id)
      setCertState({
        data: cert,
        isLoading: false
      })

      const vendorId = cert.vendorId
      const appCode = cert.appCode
      const assetCode = cert.assetCode

      const [vendor, app]: [Vendor, App] = await Promise.all([
        fetchVendor(token, vendorId),
        fetchApp(token, vendorId, appCode)
      ]).then(res => res)

      setVendorState({ data: vendor, isLoading: false })
      setAppState({ data: app, isLoading: false })

      const images: Images = await fetchImages(
        vendorId,
        appCode,
        assetCode,
        token,
        signalForFetchImage
      )
      setImages({
        data: images,
        isLoading: false
      })
    }

    f()
  }, [id, token])

  if (certState.isLoading || vendorState.isLoading || appState.isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" role="status" />
      </div>
    )
  }
  const {
    certId,
    assetCode,
    assetSeq,
    txId,
    registeredDate,
    ownerLog: owners,
    userId,
    originalUserId,
    sealId,
    series,
    creator,
    copyright,
    assetName,
    issuer
  } = certState.data

  return (
    <>
      <Row className="mb-4">
        <Col>
          <h2 className="m-0">証明書詳細</h2>
        </Col>
        <Col>
          <Button
            variant="danger"
            className="float-right"
            onClick={() => setShow(true)}
          >
            {owners.length <= 1 ? "証明書を削除する" : "前の所有者に権利を戻す"}
          </Button>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Row>
            <Col sm={8}>
              <Card>
                <Card.Header>証明書</Card.Header>
                <Card.Body className="profile">
                  <Row>
                    <Col md={3}>証明書ID:</Col>
                    <Col>
                      <span>{certId}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>トランザクションID:</Col>
                    <Col>
                      <span>{txId}</span>
                    </Col>
                  </Row>
                  {sealId && (
                    <Row>
                      <Col md={3}>シールナンバー:</Col>
                      <Col>
                        <span>{sealId}</span>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col md={3}>ハッシュ化されたユーザーID:</Col>
                    <Col>
                      <span>{userId}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>ユーザーID:</Col>
                    <Col>
                      <span>{originalUserId}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>発行主:</Col>
                    <Col>
                      <span>{issuer || "設定されていません"}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>登録日:</Col>
                    <Col>
                      <span>{toJST(registeredDate)}</span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4} className="mt-4 mt-sm-0">
              <Card>
                <Card.Header>
                  <Link to={`/vendors/${vendorState.data.vendorId}/apps`}>
                    {vendorState.data.vendorName}
                  </Link>
                  /
                  <Link
                    to={`/vendors/${vendorState.data.vendorId}/apps/${appState.data.appCode}/assets`}
                  >
                    {appState.data.appName}
                  </Link>
                </Card.Header>
                <Card.Body className="profile">
                  <Row>
                    <Col md={3}>アセット名</Col>
                    <Col>
                      <Link
                        to={`/vendors/${vendorState.data.vendorId}/apps/${appState.data.appCode}/assets/${assetCode}`}
                      >
                        {assetName}
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>証明書番号:</Col>
                    <Col>
                      <span>{assetSeq}</span>
                    </Col>
                  </Row>
                  {!!appState.data.seriesFlag && 
                    <Row>
                      <Col md={3}>作品名:</Col>
                      <Col>
                        <span>{series}</span>
                      </Col>
                    </Row>
                  }
                  <Row>
                    <Col md={3}>製作者:</Col>
                    <Col>
                      <span>{creator}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>著作権:</Col>
                    <Col>
                      <span>{copyright}</span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="mt-4">
              <Card>
                <Card.Header>所有者履歴</Card.Header>
                {OwnerLog(owners)}
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      {images.isLoading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <Spinner animation="border" role="status" />
        </div>
      ) : (
        ImageList(images.data, true)
      )}
      <Row className="my-4">
        <Col sm={2}>
          <Button variant="outline-secondary" onClick={() => history.goBack()}>
            戻る
          </Button>
        </Col>
      </Row>
      <RevokeCertificationModal
        show={show}
        onHide={() => setShow(false)}
        token={token}
        cert={certState.data}
        history={history}
      />
    </>
  )
}

export default DetailCertPage
