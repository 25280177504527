/** @jsx jsx */
import { jsx } from "@emotion/core"
import React, { Fragment, useEffect, useState } from "react"
import { Row, Col, Card, Form, Button, Spinner } from "react-bootstrap"
import { Vendors, MaybeLoading, VendorId, Apps, Certs, QueryParams } from "../types"
import { useAuth } from "../Auth"
import { RouteComponentProps } from "react-router"
import ListCertTbl from "../components/CertTable"
import { fetchVendors } from "../usecases/vendor"
import { fetchApps } from "../usecases/app"
import { fetchCerts } from "../usecases/certification"

const VendorList = ({ vendors }: Vendors) => {
  return (
    <Fragment>
      {vendors.map(vendor => (
        <option key={vendor.vendorId} value={vendor.vendorId}>
          {vendor.vendorName}
        </option>
      ))}
    </Fragment>
  )
}

const FindCertPage: React.FC<RouteComponentProps> = ({ history }) => {
  const { token } = useAuth()
  const [vendorsState, setVendorsState] = useState<MaybeLoading<Vendors>>({
    data: null,
    isLoading: true
  })
  const [appsState, setAppsState] = useState<MaybeLoading<Apps>>({
    data: null,
    isLoading: true
  })
  const [certsState, setCertsState] = useState<MaybeLoading<Certs>>({
    data: null,
    isLoading: true
  })
  const [selectedVendorId, setSelectedVendorId] = useState<VendorId | "all">(
    "all"
  )
  const [currentVendorId, setCurrentVendorId] = useState<VendorId | "all" | "">(
    ""
  )

  const [tableLoading, setTableLoading] = useState(false)

  useEffect(() => {
    const f = async () => {
      if (!token) {
        return
      }

      const apps = await fetchApps(token, {})
      setAppsState({
        data: apps,
        isLoading: false
      })

      const vendors = await fetchVendors(token, { pageNum: -1 })

      setVendorsState({
        data: vendors,
        isLoading: false
      })
    }

    f()
  }, [token])

  if (vendorsState.isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" role="status" />
      </div>
    )
  }

  const firstFind = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setTableLoading(true)

    const certs = await fetchCerts(token, {
      vendorId: selectedVendorId === "all" ? "" : selectedVendorId,
    })
    setCertsState({
      data: certs,
      isLoading: false
    })
    setCurrentVendorId(selectedVendorId)

    setTableLoading(false)
  }

  const handleLoad = async (params: QueryParams) => {
    if (currentVendorId === selectedVendorId) {
      const certs = await fetchCerts(token, {
        vendorId: selectedVendorId === "all" ? "" : selectedVendorId,
        ...params
      })
      setCertsState({
        data: certs,
        isLoading: false
      })
      setCurrentVendorId(selectedVendorId)
    }
  }

  return (
    <Fragment>
      <Row className="mb-4">
        <Col>
          <h2 className="m-0">証明書検索</h2>
        </Col>
      </Row>
      <Card>
        <Card.Body>
          <Form onSubmit={firstFind}>
            <Form.Group as={Row} controlId="formAssetId">
              <Form.Label column sm={2}>
                ベンダー
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  as="select"
                  onChange={(e: any) => setSelectedVendorId(e.target.value)}>
                  <option key="all" value="all">
                    すべてのベンダー
                  </option>
                  {VendorList(vendorsState.data)}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Col sm={{ span: 10, offset: 2 }}>
                <Button variant="primary" type="submit">
                  証明書検索
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>

      <Row>
        <Col sm={{ span: 12 }} className="text-center">
          {tableLoading && (
            <Spinner animation="border" role="status" className="mt-4" />
          )}
        </Col>
      </Row>

      {!(certsState.isLoading || appsState.isLoading || tableLoading) ? (
        <Fragment>
          <Card className="mt-3">
            <Card.Body>
              {ListCertTbl(certsState.data, appsState.data, handleLoad)}
            </Card.Body>
          </Card>
        </Fragment>
      ) : (
          <Fragment></Fragment>
        )}

      <Button
        className="mt-4"
        variant="outline-secondary"
        onClick={() => history.goBack()}>
        戻る
      </Button>
    </Fragment>
  )
}

export default FindCertPage
