/** @jsx jsx */
import { jsx } from "@emotion/core"
import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import { Certs, Cert, LoadFunction } from "../types"
import { toJST } from "../util"
import BaseTable from "./BaseTable"

const ListCertTblForAsset = ({ certs, count }: Certs, handleLoad: LoadFunction) => {
  const rows = certs.map((cert: Cert) => {
    const { registeredDate, assetSeq, userId, certId } = cert

    const detailCert = (
      <Link to={`/certifications/${certId}`}>
        <Button size="sm">詳細</Button>
      </Link>
    )

    return {
      certId,
      assetSeq,
      userId,
      registeredDate: toJST(registeredDate),
      detailCert
    }
  })

  const data = {
    columns: [
      {
        name: "証明書ID",
        selector: "certId",
      },
      {
        name: "証明書番号",
        selector: "assetSeq",
        maxWidth: "200px"
      },
      {
        name: "所有者ID",
        selector: "userId",
      },
      {
        name: "発行日",
        selector: "registeredDate",
        width: "200px"
      },
      {
        name: "",
        selector: "detailCert",
        width: "100px"
      }
    ],
    data: rows
  }

  return <BaseTable data={data} count={count} handleLoad={handleLoad} />
}

export default ListCertTblForAsset
