import React, { useEffect, useState } from "react"
import { Button, Col, Row, Spinner } from "react-bootstrap"

import { Link } from "react-router-dom"
import { useAuth } from "../Auth"
import { Vendors, MaybeLoading, QueryParams } from "../types"
import { useToast } from "../Toast"
import { fetchVendors } from "../usecases/vendor"
import BaseTable from "../components/BaseTable"

const ListVendorPage: React.FC = () => {
  const { token } = useAuth()
  const { createToast, isShow, setIsShow } = useToast()
  const [state, setState] = useState<MaybeLoading<Vendors>>({
    data: null,
    isLoading: true
  })
  const [hasError, setHasError] = useState(false)


  useEffect(() => {
    const f = async () => {
      if (!token) {
        return
      }

      try {
        const vendors = await fetchVendors(token, {})

        setState({
          data: vendors,
          isLoading: false
        })
      } catch (error) {
        setHasError(true)
      }
    }

    f()
  }, [createToast, isShow, setIsShow, token])

  if (hasError) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h2>エラーが発生しました。</h2>
        <h3>原因調査中です。</h3>
        <h3>ご迷惑をおかけして申し訳ありません。</h3>
      </div>
    )
  }

  if (state.isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" role="status" />
      </div>
    )
  }

  const { vendors, count } = state.data

  const rows = vendors.map((vendor, i) => {
    const listApp = (
      <Link
        key={`listApp${i}`}
        to={`/vendors/${vendor.vendorId}/apps`}
        className="mr-3">
        <Button size="sm" variant="success">
          アプリ一覧
        </Button>
      </Link>
    )
    const editVendor = (
      <Link
        key={`edit${i}`}
        to={`/vendors/${vendor.vendorId}/edit`}
        className="mr-3">
        <Button size="sm" variant="warning">
          修正
        </Button>
      </Link>
    )
    const buttons = [listApp, editVendor]

    return {
      ...vendor,
      buttons
    }
  })

  const data = {
    columns: [
      {
        name: "ベンダーID",
        selector: "vendorId",
        sortable: true
      },
      {
        name: "ベンダー名",
        selector: "vendorName",
        sortable: true
      },
      {
        name: "担当者名",
        selector: "staffName"
      },
      {
        name: "担当者Email",
        selector: "staffEmail"
      },
      {
        name: "",
        selector: "buttons"
      }
    ],
    data: rows
  }

  const handleLoad = async (params: QueryParams) => {
    const vendors = await fetchVendors(token, params)

    setState({
      data: vendors,
      isLoading: false
    })
  }

  return (
    <>
      <Row className="mb-4">
        <Col>
          <h2 className="m-0">ベンダー一覧</h2>
        </Col>
        <Col>
          <Link to="/add_vendor">
            <Button className="float-right">
              <p className="d-inline-block ml-0 mb-0">ベンダーを追加</p>
            </Button>
          </Link>
        </Col>
      </Row>

      <BaseTable data={data} count={count} handleLoad={handleLoad} />
    </>
  )
}


export default ListVendorPage
