import React from "react"
import ReactDOM from "react-dom"
import "bootstrap-css-only/css/bootstrap.min.css"
import "./index.css"
import "react-dates/initialize"
import App from "./App"
import { AuthProvider } from "./Auth"
import { ToastProvider } from "./Toast"
import * as serviceWorker from "./serviceWorker"

ReactDOM.render(
  <AuthProvider
    domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
    client_id={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE || ""}
    redirect_uri={process.env.REACT_APP_AUTH0_REDIRECT_URI || ""}>
    <ToastProvider>
      <App />
    </ToastProvider>
  </AuthProvider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
