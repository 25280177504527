/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { Card, Row, Col, Image } from "react-bootstrap"
import { Images } from "../types"
import noimage from "./noimage.png"

const imgStyle = css`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
`

const style = (isThumbnail: boolean) => css`
  max-width: ${isThumbnail ? 400 : 600}px;
  height: ${isThumbnail ? 400 : 600}px;
  overflow: hidden;
`

const ImageList = (images: Images, isThumbnail: boolean = false) => (
  <Row>
    {images.map((image, i) => (
      <Col xs={12} sm={6} md={4} key={i}>
        <Card bg="light" className="mb-3" css={style(isThumbnail)}>
          <Card.Body>
            <Card.Title>画像{i + 1}</Card.Title>
          </Card.Body>
          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <Image src={image || noimage} css={imgStyle} />
          </div>
        </Card>
      </Col>
    ))}
  </Row>
)

export default ImageList
