import React, { useEffect, useState } from "react"
import { Spinner, Col, Card, Row, Button } from "react-bootstrap"
import { RouteComponentProps } from "react-router"
import { useAuth } from "../Auth"
import { useToast } from "../Toast"
import { SuccessModal, FailModal } from "../components/Modal"
import { fetchApp, updateApp } from "../usecases/app"
import AppForm from "../components/AppForm"
import validator from "../validators/app"

const EditAppPage: React.FC<
  RouteComponentProps<{ vendorId: string; appCode: string }>
> = ({ match, history }) => {
  const { token } = useAuth()
  const { createToast, isShow, setIsShow } = useToast()

  const vendorId = match.params.vendorId
  const appCode = match.params.appCode

  const [initialValues, setInitialValues] = useState<{
    vendorId: string
    appCode: string
    appName: string
    priceFlag: string
    seqFlag: string
    sealFlag: string
    sealPrefix: string
    sealToUserFlag: string
    seriesFlag: string
    issuer: string
    infoUrl: string
  }>({
    vendorId,
    appCode,
    appName: "",
    priceFlag: "0",
    seqFlag: "0",
    sealFlag: "0",
    sealPrefix: "",
    sealToUserFlag: "0",
    seriesFlag: "0",
    issuer: "",
    infoUrl: ""
  })

  const onSubmit = (values: typeof initialValues) => {
    submit(values)
  }

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const f = async () => {
      if (!token) {
        return
      }

      try {
        const app = await fetchApp(token, vendorId, appCode)

        // 本来API側で修正すべきだが、ここで対応
        let sealPrefix = app.sealPrefix
        if (!sealPrefix) {
          sealPrefix = ""
        }
        setInitialValues({
          vendorId,
          appCode,
          appName: app.appName,
          priceFlag: String(app.priceFlag),
          seqFlag: String(app.seqFlag),
          sealFlag: String(app.sealFlag),
          sealPrefix: String(sealPrefix),
          sealToUserFlag: String(app.sealToUserFlag),
          seriesFlag: String(app.seriesFlag),
          issuer: app.issuer,
          infoUrl: app.infoUrl
        })

        setIsLoading(false)
      } catch (error) {
        const errorMessage = error.response.data.message
        const title = "アプリ情報取得"

        setIsShow(true)
        createToast(() => () => (
          <FailModal
            show={isShow}
            handleClose={() => setIsShow(false)}
            title={title}
            message={errorMessage}
          />
        ))
      }
    }

    f()
  }, [createToast, isShow, setIsShow, token, vendorId, appCode])

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" role="status" />
      </div>
    )
  }

  const submit = async (values: {
    vendorId: string
    appCode: string
    appName: string
    issuer: string
    priceFlag: string
    seqFlag: string
    sealFlag: string
    sealPrefix: string
    sealToUserFlag: string
    seriesFlag: string
    infoUrl: string
  }) => {
    const title = "アプリ情報修正"

    try {
      await updateApp(
        token,
        values.vendorId,
        values.appCode,
        values.appName,
        values.issuer,
        values.priceFlag,
        values.seqFlag,
        values.sealFlag,
        values.sealPrefix,
        values.sealToUserFlag,
        values.seriesFlag,
        values.infoUrl
      )

      setIsShow(true)
      createToast(() => () => (
        <SuccessModal
          show={isShow}
          handleClose={() => setIsShow(false)}
          title={title}
        />
      ))
    } catch (error) {
      const errorMessage = error.response.data.message

      setIsShow(true)
      createToast(() => () => (
        <FailModal
          show={isShow}
          handleClose={() => setIsShow(false)}
          title={title}
          message={errorMessage}
        />
      ))
    }
  }

  return (
    <>
      <Row className="mb-4">
        <Col>
          <h2 className="m-0">アプリ修正</h2>
        </Col>
      </Row>
      <Card>
        <Card.Body>
          <AppForm
            initialValues={initialValues}
            validationSchema={validator}
            onSubmit={onSubmit}
            isEdit
          />
        </Card.Body>
      </Card>
      <Button
        className="mt-4"
        variant="outline-secondary"
        onClick={() => history.goBack()}
      >
        戻る
      </Button>
    </>
  )
}

export default EditAppPage
