import React from "react"
import { Link } from "react-router-dom"
import { Histories, LoadFunction } from "../types"
import { toJST, currencyToMessage } from "../util"
import BaseTable from "./BaseTable"

const HistoryTable = ({ histories, count }: Histories, handleLoad: LoadFunction) => {
  const rows = histories.map(history => {
    const {
      type,
      vendorName,
      appName,
      assetName,
      assetSeq,
      price,
      registeredDate,
      certId
    } = history

    const ellipsisDiv = (content: string) => (
      <div className="baseEllipsis pt-2">
        <p className="ellipsisContent">
          <Link to={`/certifications/${content}`}>{content}</Link>
        </p>
      </div>
    )

    const detailCert = ellipsisDiv(certId)

    return {
      type,
      vendorName,
      appName,
      assetName,
      assetSeq,
      price: price ? `${price} ${currencyToMessage(history.currency)}` : " ",
      registeredDate: toJST(registeredDate),
      detailCert
    }
  })

  const data = {
    columns: [
      {
        name: "種別",
        selector: "type",
      },
      {
        name: "ベンダー名",
        selector: "vendorName",
      },
      {
        name: "アプリ名",
        selector: "appName",
      },
      {
        name: "アセット名",
        selector: "assetName",
      },
      {
        name: "証明書番号",
        selector: "assetSeq",

        width: "75px"
      },
      {
        name: "金額",
        selector: "price",
        minWidth: "80px",
        sortable: true
      },
      {
        name: "日時",
        selector: "registeredDate",
        sortable: true
      },
      {
        name: "証明書",
        selector: "detailCert",
        minWidth: "600px"
      }
    ],
    data: rows
  }

  return <BaseTable data={data} count={count} handleLoad={handleLoad} />
}

export default HistoryTable
