import React from "react"
import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import { Certs, Apps, Cert, LoadFunction } from "../types"
import { toJST } from "../util"
import BaseTable from "./BaseTable"

const ListCertTbl = ({ certs, count }: Certs, { apps }: Apps, handleLoad: LoadFunction) => {
  const rows = certs.map((cert: Cert) => {
    const {
      registeredDate,
      certId,
      assetSeq,
      userId,
      assetName,
      appName,
      vendorName
    } = cert

    const detailCert = (
      <Link to={`/certifications/${certId}`}>
        <Button size="sm">詳細</Button>
      </Link>
    )

    return {
      certId: certId,
      vendorName,
      appName,
      assetName,
      assetSeq,
      hashUserId: userId,
      registeredDate: toJST(registeredDate),
      detailCert
    }
  })

  const data = {
    columns: [
      {
        name: "証明書ID",
        selector: "certId",
        sortable: true
      },
      {
        name: "ベンダー名",
        selector: "vendorName",
        sortable: true
      },
      {
        name: "アプリ名",
        selector: "appName",
        sortable: true
      },
      {
        name: "アセット名",
        selector: "assetName"
      },
      {
        name: "証明書番号",
        selector: "assetSeq",
        sortable: true,
        width: "100px"
      },
      {
        name: "所有者ID",
        selector: "hashUserId"
      },
      {
        name: "発行日",
        selector: "registeredDate",
        sortable: true,
        width: "175px"
      },
      {
        name: "",
        selector: "detailCert",
        width: "80px"
      }
    ],
    data: rows
  }

  return <BaseTable data={data} count={count} handleLoad={handleLoad} />
}

export default ListCertTbl
