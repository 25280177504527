/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { RouteComponentProps } from "react-router"
import ImageList from "../components/ImageList"
import ListCertTblForAsset from "../components/CertTableForAsset"
import { useAuth } from "../Auth"
import { toJST, fetchImages } from "../util"
import {
  Asset,
  Images,
  MaybeLoading,
  Vendor,
  App,
  Certs,
  QueryParams,
} from "../types"
import { Spinner, Col, Card, Row, Button } from "react-bootstrap"
import { fetchAsset } from "../usecases/asset"
import { fetchVendor } from "../usecases/vendor"
import { fetchApp } from "../usecases/app"
import { fetchCerts } from "../usecases/certification"
import { Link } from "react-router-dom"

const DetailAssetPage: React.FC<
  RouteComponentProps<{
    vendorId: string
    appCode: string
    assetCode: string
  }>
> = ({ match, history }) => {
  const { token } = useAuth()
  const [images, setImages] = useState<MaybeLoading<Images>>({
    data: null,
    isLoading: true
  })
  const [asset, setAsset] = useState<MaybeLoading<Asset>>({
    data: null,
    isLoading: true
  })
  const [vendorState, setVendorState] = useState<MaybeLoading<Vendor>>({
    data: null,
    isLoading: true
  })
  const [appState, setAppState] = useState<MaybeLoading<App>>({
    data: null,
    isLoading: true
  })
  const [certsState, setCertsState] = useState<MaybeLoading<Certs>>({
    data: null,
    isLoading: true
  })
  const [page, setPage] = useState(0)
  const { vendorId, appCode, assetCode } = match.params

  useEffect(() => {
    if (!token) {
      return
    }

    const signal = axios.CancelToken.source()
    const signalForFetchImage = axios.CancelToken.source()

    const f = async () => {
      try {
        const asset = await fetchAsset(token, vendorId, appCode, assetCode)

        setAsset({
          data: asset,
          isLoading: false
        })

        const [vendor, app, certs]: [Vendor, App, Certs] = await Promise.all([
          fetchVendor(token, vendorId),
          fetchApp(token, vendorId, appCode),
          fetchCerts(token, { vendorId, appCode, assetCode, page })
        ]).then(res => res)

        setPage(page + 1)
        setVendorState({ data: vendor, isLoading: false })
        setAppState({ data: app, isLoading: false })
        setCertsState({ data: certs, isLoading: false })

        const images: Images = await fetchImages(
          vendorId,
          appCode,
          assetCode,
          token,
          signalForFetchImage
        )

        setImages({
          data: images,
          isLoading: false
        })
      } catch (error) {
        if (error.message === "canceled") {
          return
        }
        console.log(error)
      }
    }

    f()

    return () => {
      signal.cancel("canceled")
      signalForFetchImage.cancel("canceled")
    }
    // eslint-disable-next-line
  }, [appCode, assetCode, token, vendorId])

  const handleLoad = async (params: QueryParams) => {
    const certs = await fetchCerts(token, {
      vendorId,
      appCode,
      assetCode,
      ...params
    })
    setCertsState({
      data: {
        certs: certs.certs,
        count: certs.count
      },
      isLoading: false
    })
  }

  if (
    asset.isLoading ||
    vendorState.isLoading ||
    appState.isLoading ||
    certsState.isLoading
  ) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" role="status" />
      </div>
    )
  }

  const {
    assetName,
    assetDesc,
    assetHash,
    series,
    limit,
    creator,
    copyright,
    registeredDate,
    updatedDate
  } = asset.data

  return (
    <React.Fragment>
      <Row className="mb-4">
        <Col>
          <h2 className="m-0">アセット詳細</h2>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Link to={`/vendors/${vendorState.data.vendorId}/apps`}>
                {vendorState.data.vendorName}
              </Link>
              /
              <Link
                to={`/vendors/${vendorState.data.vendorId}/apps/${appState.data.appCode}/assets`}
              >
                {appState.data.appName}
              </Link>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={3}>アセット名:</Col>
                <Col>
                  <span>{assetName}</span>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col md={3}>アセットコード:</Col>
                <Col>
                  <span>{assetCode}</span>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col md={3}>アセットデータハッシュ値:</Col>
                <Col>
                  <span>{assetHash}</span>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col md={3}>説明:</Col>
                <Col>
                  <div
                    css={css`
                      width: 40rem;
                    `}
                  >
                    <span>{assetDesc}</span>
                  </div>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col md={3}>発行数:</Col>
                <Col>
                  <span>
                    {certsState.data.count} /{" "}
                    {limit === -1 ? "制限なし" : limit}
                  </span>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col md={3}>製作者:</Col>
                <Col>
                  <span>{creator}</span>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col md={3}>著作権:</Col>
                <Col>
                  <span>{copyright}</span>
                </Col>
              </Row>
              {!!appState.data.seriesFlag && (
                <Row className="mt-1">
                  <Col md={3}>作品名:</Col>
                  <Col>
                    <span>{series}</span>
                  </Col>
                </Row>
              )}
              <Row className="mt-1">
                <Col md={3}>登録日:</Col>
                <Col>
                  <span>{toJST(registeredDate)}</span>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col md={3}>更新日:</Col>
                <Col>
                  <span>{toJST(updatedDate)}</span>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {ListCertTblForAsset(certsState.data, handleLoad)}

      {images.isLoading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <Spinner animation="border" role="status" />
        </div>
      ) : (
        ImageList(images.data)
      )}

      <Button
        className="my-4"
        variant="outline-secondary"
        onClick={() => history.goBack()}
      >
        戻る
      </Button>
    </React.Fragment>
  )
}

export default DetailAssetPage
