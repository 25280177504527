import React from "react"
import { RouteComponentProps } from "react-router"
import { Card, Col, Row, Button } from "react-bootstrap"
import { useAuth } from "../Auth"
import { useToast } from "../Toast"
import { SuccessModal, FailModal } from "../components/Modal"
import { addApp } from "../usecases/app"
import AppForm from "../components/AppForm"
import validator from "../validators/app"

const AddAppPage: React.FC<RouteComponentProps<{ vendorId: string }>> = ({
  match,
  history
}) => {
  const { token } = useAuth()
  const { createToast, isShow, setIsShow } = useToast()

  const initialValues = {
    vendorId: match.params.vendorId,
    appCode: "",
    appName: "",
    priceFlag: "0",
    seqFlag: "0",
    sealFlag: "0",
    sealPrefix: "",
    sealToUserFlag: "0",
    seriesFlag: "0",
    issuer: "",
    infoUrl: ""
  }

  const onSubmit = (values: typeof initialValues) => {
    submit(values)
  }

  const submit = async (values: {
    vendorId: string
    appCode: string
    appName: string
    issuer: string
    priceFlag: string
    seqFlag: string
    sealFlag: string
    sealPrefix: string
    sealToUserFlag: string
    seriesFlag: string
    infoUrl: string
  }) => {
    const title = "アプリ情報登録"

    try {
      await addApp(
        token,
        values.vendorId,
        values.appCode,
        values.appName,
        values.issuer,
        values.priceFlag,
        values.seqFlag,
        values.sealFlag,
        values.sealPrefix,
        values.sealToUserFlag,
        values.seriesFlag,
        values.infoUrl
      )
      history.goBack()

      setIsShow(true)
      createToast(() => () => (
        <SuccessModal
          show={isShow}
          handleClose={() => setIsShow(false)}
          title={title}
        />
      ))
    } catch (error) {
      const errorMessage = error.response.data.message
      console.log(errorMessage)

      setIsShow(true)
      createToast(() => () => (
        <FailModal
          show={isShow}
          handleClose={() => setIsShow(false)}
          title={title}
        />
      ))
    }
  }

  return (
    <>
      <Row className="mb-4">
        <Col>
          <h2 className="m-0">アプリ登録</h2>
        </Col>
      </Row>
      <Card>
        <Card.Body>
          <AppForm
            initialValues={initialValues}
            validationSchema={validator}
            onSubmit={onSubmit}
          />
        </Card.Body>
      </Card>

      <Button
        className="mt-4"
        variant="outline-secondary"
        onClick={() => history.goBack()}>
        戻る
      </Button>
    </>
  )
}

export default AddAppPage
